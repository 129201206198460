import { createApp } from "vue";
import kebabCase from "lodash/kebabCase";

// https://fontawesome.com/v5/docs/apis/javascript/import-icons#package-names
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faPhone,
  faDownload,
  faArrowRight,
  faArrowUpRightFromSquare,
  faChevronDown,
  faMagnifyingGlass,
  faBars,
  faXmark,
  faUser,
  faSliders
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareTwitter,
  faSquareFacebook,
  faSquareInstagram
} from "@fortawesome/free-brands-svg-icons";
import { faArrowDownToBracket } from "@fortawesome/pro-solid-svg-icons";

library.add(
  faEnvelope,
  faPhone,
  faDownload,
  faArrowRight,
  faArrowUpRightFromSquare,
  faChevronDown,
  faMagnifyingGlass,
  faBars,
  faXmark,
  faUser,
  faSliders,

  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareTwitter,
  faSquareFacebook,
  faSquareInstagram,

  faArrowDownToBracket
);

dom.watch();

const vueApp = createApp({});

const getAllComponents = () => {
  const components = [];

  // Collect all components using webpack's require.context()
  const r = require.context("./", true, /[A-Z]\w+\.(vue)$/);

  r.keys().forEach(filename => {
    const component = r(filename).default;
    component.name = component.name || filename.match("([^/]+).vue$")[1];
    component.delimiters = component.delimiters || ["${", "}"];
    const elementName = kebabCase(component.name);
    if (elementName.indexOf("-") === -1) {
      console.warn(`element name must contain a dash, rename "${elementName}"`); // eslint-disable-line no-console
      return;
    }
    vueApp.component(elementName, component);
    components.push({
      elementName,
      component
    });
  });

  return components;
};

const registeredComponents = getAllComponents();

const detectedElements = [];
for (const { elementName } of registeredComponents) {
  detectedElements.push(...document.querySelectorAll(elementName));
}

// Only mount root components that are found in the rendered HTML
for (const { elementName, component } of registeredComponents) {
  for (const el of document.querySelectorAll(elementName)) {
    let parent = el.parentNode;
    let mount = true;
    while (parent) {
      if (detectedElements.indexOf(parent) !== -1) {
        mount = false; // this element will be mounted as subcomponent
        break;
      }
      parent = parent.parentNode;
    }
    if (mount) {
      vueApp.component(elementName, component);
    }
  }
}

vueApp.mount("#vue3-app");
